.search {
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &[type="search"]::-webkit-search-cancel-button {
    appearance: none;
    height: 1.25rem;
    width: 1.25rem;
    background: url("../js/view/icons/Close.png") no-repeat 50% 50%;
    background-size: contain;
    opacity: 1;
    cursor: pointer;
  }
}
