@import "_fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .zebra-list-item {
        @apply even:bg-black-1 odd:bg-black-0;
    }
}

html, body, #root {
    overflow-x: hidden;
    height: 100%;
}
